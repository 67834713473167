import Controller from '@ember/controller';
import { ModelFrom } from 'vault-client/utils/type-utils';
import HedgeStrategyRoute from 'vault-client/routes/businesses/business/hedge-strategies/hedge-strategy';
import { CellComponents, TableColumn } from 'vault-client/types/vault-table';
import { service } from '@ember/service';
import MarketDataService from 'vault-client/services/market-data';
import {
	Swap,
	Transaction,
	Future,
	Swaption,
	Option,
	Instrument,
	RmaCommodity,
	RmaType,
	InsurancePolicy,
	BrokerageAccount,
	Account,
	HedgeStrategyHistoricalPnl,
	TypeOfInstrument,
	TypeOfInsuranceEndorsement,
	TypeOfOption,
	Query,
	InsuranceScenarioPricingOutput,
	Query_insuranceScenarioPricingForFixedPriceSetsArgs,
	LgmInsuranceEndorsement,
	LrpInsuranceEndorsement,
	BusinessEntityRole,
} from 'vault-client/types/graphql-types';
import FutureTransaction from 'vault-client/models/future-transaction';
import OptionTransaction from 'vault-client/models/option-transaction';
import SwapTransaction from 'vault-client/models/swap-transaction';
import SwaptionTransaction from 'vault-client/models/swaption-transaction';
import { getOwner } from '@ember/application';
import { DateTime } from 'luxon';
import intlDateTimeFormat from 'vault-client/utils/intl-date-time-format';
import Big from 'big.js';
import { ChartOptions, ChartData, ChartDataset, Chart } from 'chart.js';
import { getCustomTooltip, CustomTooltipOptions, getCustomLegend } from 'vault-client/utils/chart-utils';
import { action } from '@ember/object';
import { gql, useQuery } from 'glimmer-apollo';
import LrpInsuranceEndorsementModel from 'vault-client/models/lrp-endorsement';
import LgmInsuranceEndorsementModel from 'vault-client/models/lgm-endorsement';
export type TransactionQuery = Transaction & {
	Instrument: Swap &
		Future &
		Swaption &
		Option & { PriceInstrument: Instrument & Swap & Future & Option & Swaption & { barchartSymbol: String } };
	PricingInstrument?: Swap & Future & Option & Swaption & { barchartSymbol: String };
	lastPrice?: number;
	BrokerageAccount?: BrokerageAccount;
};

export type modeledTransaction = {
	grossPl?: number | null;
	Account?: Account;
	Instrument?: unknown;
	side?: string;
	absQuantityInContracts?: Big | number;
	_commissionTotal?: number;
	_feeTotal?: number;
	netPl?: number | null;
};

export type lrpEndorsementObj = LrpInsuranceEndorsement & {
	coverageEndDate: string | null;
	RmaCommodity: RmaCommodity | null;
	headCount: number | null;
	insuredValue: number | null;
	lengthInWeeks: number | null;
	targetWeightCwt: number | null;
	subsidyAmount: number | null;
	producerPremiumAmount: number | null;
	isAdded?: boolean;
};

export type LgmInsuranceEndorsementByCoverageMonth = LgmInsuranceEndorsement & {
	id: string;
	coverageMonth?: string;
	startDate?: string;
	endDate?: string;
	target?: number;
	monthIndex?: number;
	expectedGrossMargin?: number;
	corn?: number;
	sbm?: number;
	expectedFeedCost?: number;
	children?: Array<PerMonthData>;
	coverageMonths?: Array<string>;
	perMonthData?: Array<PerMonthData>;
	pnl?: number | null;
	RmaType?: RmaType | null;
	producerPremiumAmount?: number | null;
	totalExpectedGrossMargin?: number | null;
	InsurancePolicy?: InsurancePolicy | null;
	salesEffectiveDate?: string | null;
	forecastedIndemnity?: number | null;
	deductibleAmount?: number | null;
	grossMarginGuarantee?: number | null;
	RmaCommodity?: RmaCommodity | null;
	hedgeStrategyId?: string | null;
	isCollapsed?: boolean;
	insuranceEndorsementId?: string | null;
	rmaTypeName?: string | null;
	producerName?: string | null;
	agentFullName?: string | null;
	lgmModelData?: LgmInsuranceEndorsementModel | null | undefined;
};

export interface PerMonthData {
	coverageMonth?: string | null;
	startDate?: string | null;
	endDate?: string | null;
	monthIndex?: number | null;
	target?: number | null;
	corn?: number | null;
	expectedFeedCost?: number | null;
	expectedGrossMargin?: number | null;
	sbm?: number | null;
}

type HistoricalPnlDataObj = {
	strategyPnl: number[];
	brokeragePnl: number[];
	insurancePnl: number[];
};

type NetHedgePrice = {
	['Market Price']: { x: number; y: number }[];
	['Net Hedge Price']: { x: number; y: number }[];
};

type NetHedgePl = {
	Brokerage: { x: number; y: number }[];
	LRP: { x: number; y: number }[];
	LGM: { x: number; y: number }[];
	'Net Hedge': { x: number; y: number }[];
};

const INSURANCE_SCENARIO_PRICING = gql`
	query insuranceScenarioPricingForFixedPriceSets($input: InsuranceScenarioPricingFixedPriceSetsInput!) {
		insuranceScenarioPricingForFixedPriceSets(input: $input) {
			totalPnl
		}
	}
`;

type insuranceScenarioQuery = Query['insuranceScenarioPricingForFixedPriceSets'] & {
	insuranceScenarioPricingForFixedPriceSets: InsuranceScenarioPricingOutput[];
};

export default class BusinessesBusinessHedgeStrategiesHedgeStrategyController extends Controller {
	@service marketData!: MarketDataService;
	declare model: ModelFrom<HedgeStrategyRoute>;

	brokerageRoute = 'businesses.business.transactions';
	lrpEndorsementRoute = 'businesses.business.lrp-insurance-endorsements';
	lgmEndorsementRoute = 'businesses.business.lgm-insurance-endorsements';

	get businessRoles() {
		return this.model.hedgeStrategy?.data?.HedgeStrategy?.Business.businessRoles;
	}

	get displayLrp() {
		const lrpRoles = [BusinessEntityRole.HogProducer, BusinessEntityRole.CattleProducer];
		return this.businessRoles?.some((role) => lrpRoles.includes(role)) ?? false;
	}

	get displayLgm() {
		const lgmRoles = [BusinessEntityRole.HogProducer, BusinessEntityRole.CattleProducer, BusinessEntityRole.DairyProducer];
		return this.businessRoles?.some((role) => lgmRoles.includes(role)) ?? false;
	}

	get brokerageColumns(): TableColumn[] {
		const baseColumns = [
			{
				id: '634b1da4-e044-4034-80c2-93b471dc7b48',
				name: 'Account Name',
				valuePath: 'Account.name',
				cellComponent: CellComponents.String,
				width: 140,
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: false,
			},
			{
				id: '2ce9a8b2-5743-494e-9c5e-50054f0b2631',
				name: 'Sales Code',
				valuePath: 'Account.salesCode',
				cellComponent: CellComponents.String,
				width: 100,
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: false,
			},
			{
				id: '334e8447-a3b0-4043-bc0e-6a05883a98c0',
				name: 'Account',
				valuePath: 'Account.accountNumber',
				cellComponent: CellComponents.String,
				width: 100,
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'c5aa1858-b47d-4caf-99e6-9a51b3c55178',
				name: 'Trade Day',
				valuePath: 'tradeDate',
				cellComponent: CellComponents.IntlDateTimeFormat,
				componentArgs: { day: 'numeric', month: 'numeric', year: 'numeric' },
				width: 130,
				textAlign: 'left',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'b11f9cbe-d948-4032-b2b0-4824194e0c16',
				name: 'Commodity',
				valuePath: 'Instrument.Product.name',
				width: 150,
				textAlign: 'left',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '00fba2b2-f896-4a88-87db-7d95460e197d',
				name: 'Contracts',
				valuePath: 'contractQuantity',
				linkRoute: 'businesses.business.transaction',
				linkModelPath: 'id',
				width: 100,
				cellComponent: CellComponents.IntlNumberFormat,
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			// when adding delta quantity column add it here. Check this: https://linear.app/ever-ag/issue/VF-2803/hedge-strategy-brokerage-table-default-column-order
			{
				id: '7874569a-c9f2-47b5-964d-3f84b0d73a25',
				name: 'Type',
				valuePath: 'Instrument.instrumentType',
				cellComponent: CellComponents.String,
				width: 80,
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '2e781196-9917-4b6c-a425-162e3c350542',
				name: 'Exp Month',
				valuePath: 'Instrument.displayExpiresAt',
				width: 120,
				cellComponent: CellComponents.MonthFormat,
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '1b401cf0-6987-4f73-b775-0facaf2b926b',
				name: 'Trade Price',
				valuePath: 'price',
				width: 130,
				cellComponent: CellComponents.PriceFormat,
				componentArgs: {
					fractionDigitsPath: 'Instrument.symbolGroup.fractionDigits',
					displayFactorPath: 'Instrument.symbolGroup.displayFactor',
				},
				textAlign: 'right',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'b0c74ec0-c387-4334-a17f-8cad1d8548e0',
				name: 'Strike',
				valuePath: 'Instrument.strike',
				width: 90,
				cellComponent: CellComponents.PriceFormat,
				componentArgs: {
					fractionDigitsPath: 'Instrument.SymbolGroup.fractionDigits',
					displayFactorPath: 'Instrument.SymbolGroup.displayFactor',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'ca008bbd-1104-4c57-9130-cb9c36b9c0d7',
				name: 'Last Price',
				valuePath: 'lastPrice',
				minWidth: 120,
				cellComponent: CellComponents.PriceFormat,
				componentArgs: {
					fractionDigitsPath: 'Instrument.symbolGroup.fractionDigits',
					displayFactorPath: 'Instrument.symbolGroup.displayFactor',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'e828f3d4-80b5-45d1-b690-2ce17d6a0546',
				name: 'Net P/L',
				valuePath: 'netPl',
				minWidth: 120,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '8ad4c1f2-ffd9-4456-968f-258d79a9080f',
				name: 'ABS Quantity',
				valuePath: 'absQuantityInContracts',
				cellComponent: CellComponents.String,
				width: 120,
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: false,
			},
			{
				id: '4573e7f8-ad49-4690-8960-96526227a907',
				name: 'B / S',
				valuePath: 'side',
				cellComponent: CellComponents.String,
				width: 70,
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: false,
			},
			{
				id: 'd325d1c9-e9b6-4081-a360-ba8350c60b69',
				name: 'Symbol',
				valuePath: 'Instrument.exchangeSymbol',
				cellComponent: CellComponents.String,
				width: 100,
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: false,
			},
			{
				id: '99887388-18ea-4da8-ab73-f9d1813622e5',
				name: 'Commission',
				valuePath: 'commission',
				width: 130,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: false,
			},
			{
				id: 'd99049da-293e-4231-ba17-bb5ed7afafa7',
				name: 'Fees',
				valuePath: 'fees',
				width: 100,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: false,
			},
			{
				id: 'd703bff6-4401-44d1-b9ed-5e93c2719b2a',
				name: 'Gross P/L',
				valuePath: 'grossPl',
				width: 140,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: false,
			},
		];
		return baseColumns;
	}

	get lrpColumns(): TableColumn[] {
		const baseColumns = [
			{
				id: 'bc880155-f7ac-40e7-b50a-f5b321fa6d81',
				name: 'Sales Date',
				valuePath: 'salesEffectiveDate',
				cellComponent: CellComponents.IntlDateTimeFormat,
				componentArgs: { month: 'numeric', day: 'numeric', year: 'numeric' },
				width: 115,
				textAlign: 'left',
				isSortable: true,
				isFixed: '',
				isVisible: true,
				linkRoute: 'businesses.business.lrp-insurance-endorsement',
				linkModelPath: 'insuranceEndorsementId',
			},
			{
				id: '795ef295-d23e-43af-9371-89e6c1de112b',
				name: 'Coverage End Date',
				valuePath: 'coverageEndDate',
				cellComponent: CellComponents.IntlDateTimeFormat,
				componentArgs: { month: 'numeric', day: 'numeric', year: 'numeric' },
				width: 165,
				textAlign: 'left',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '2efc0b74-1707-4e91-aa92-7b0326e78abf',
				name: 'Target Weight (CWT)',
				valuePath: 'targetWeightCwt',
				width: 185,
				cellComponent: CellComponents.IntlNumberFormat,
				textAlign: 'right',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'f720a303-7660-4e5d-a53d-756f2117aa52',
				name: 'Delta Quantity',
				valuePath: 'lrpModelData.delta',
				width: 70,
				cellComponent: CellComponents.NumericFormat,
				componentArgs: {
					maximumFractionDigits: '1',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '2dfd8d0b-c8a9-4e83-8703-a78b0a4ad16c',
				name: 'Total Premium',
				valuePath: 'totalPremium',
				width: 140,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: true,
				isFixed: '',
				isVisible: false,
				isTotaled: true,
			},
			{
				id: '117777a4-2721-4fda-83c1-a4e2e03f6efd',
				name: 'Producer Premium',
				valuePath: 'producerPremiumAmount',
				width: 170,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
					minimumFractionDigits: 0,
					maximumFractionDigits: 0,
				},
				textAlign: 'right',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '456c7f05-6d83-4eb4-83de-5cf45ad0a0b8',
				name: 'Est. Indemnity',
				valuePath: 'indemnity',
				width: 160,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
					minimumFractionDigits: 0,
					maximumFractionDigits: 0,
				},
				textAlign: 'right',
				isSortable: true,
				isFixed: '',
				isVisible: true,
				isTotaled: true,
			},
			{
				id: '0409572a-9f4d-4812-a693-403908f42032',
				name: 'P/L',
				valuePath: 'pnl',
				width: 110,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
					minimumFractionDigits: 0,
					maximumFractionDigits: 0,
				},
				textAlign: 'right',
				isSortable: true,
				isFixed: '',
				isVisible: true,
				isTotaled: true,
			},
			{
				id: 'f242ac2e-2c27-466c-8159-5d16358932fc',
				name: 'Producer',
				valuePath: 'producerName',
				cellComponent: CellComponents.String,
				width: 125,
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: false,
			},
			{
				id: 'afd5f5fa-b11a-4aef-b615-28f5648f69a1',
				name: 'price',
				valuePath: 'price',
				width: 110,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: true,
				isFixed: '',
				isVisible: false,
				isTotaled: true,
			},

			{
				id: '669e4200-2b35-444d-93e0-2a36ea39bfdd',
				name: 'Insured Value',
				valuePath: 'insuredValue',
				width: 130,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: true,
				isFixed: '',
				isVisible: false,
				isTotaled: true,
			},
			{
				id: '2f762876-3423-401f-ad46-ec1469c0d29a',
				name: 'Length (weeks)',
				valuePath: 'lengthInWeeks',
				width: 150,
				cellComponent: CellComponents.IntlNumberFormat,
				textAlign: 'right',
				isSortable: true,
				isFixed: '',
				isVisible: false,
			},
			{
				id: '641c42a4-e513-4270-a631-36e35f4ebebc',
				name: 'Subsidy',
				valuePath: 'subsidyAmount',
				width: 120,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: true,
				isFixed: '',
				isVisible: false,
				isTotaled: true,
			},
			{
				id: '39261aaa-6337-4f2f-843f-d7f2cc02d24e',
				name: 'State',
				valuePath: 'state',
				width: 90,
				cellComponent: CellComponents.String,
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: false,
			},
			{
				id: 'd64dcd54-36e6-4610-bcf6-8825f5cdfd45',
				name: 'Commodity',
				valuePath: 'commodityName',
				cellComponent: CellComponents.String,
				width: 110,
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: false,
			},
			{
				id: '877b47ef-0c93-45eb-bf7d-645e6403f623',
				name: 'Type',
				valuePath: 'type',
				width: 100,
				cellComponent: CellComponents.String,
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: false,
			},
			{
				id: '3047fa83-2436-440b-92de-eaa3e838a88a',
				name: 'Head Count',
				valuePath: 'headCount',
				width: 110,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'decimal',
					minimumFractionDigits: 3,
					maximumFractionDigits: 3,
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: false,
			},
			{
				id: '4cdaf733-4f84-474f-939e-440820cf2380',
				name: 'Policy',
				valuePath: 'policyNumber',
				width: 150,
				textAlign: 'left',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: false,
			},
			{
				id: '0694a53c-85a7-453c-8ff3-d698c4b46d6d',
				name: 'Agency',
				valuePath: 'agencyName',
				width: 200,
				textAlign: 'right',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: false,
			},
			{
				id: '52ad6bed-43d2-467b-a9db-f2e3e0db131f',
				name: 'AIP',
				valuePath: 'aipName',
				width: 120,
				textAlign: '',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: false,
			},
			{
				id: 'c6b546bb-6da2-4232-8462-bde8eb76eeaf',
				name: 'Agent',
				valuePath: 'agentFullName',
				width: 170,
				textAlign: 'right',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: false,
			},
		];
		return baseColumns;
	}

	get lgmColumns(): TableColumn[] {
		const baseColumns = [
			{
				id: '8686db5b-7296-4fb9-adf5-5ba04c703c26',
				name: 'Sales Date',
				valuePath: 'salesEffectiveDate',
				cellComponent: CellComponents.IntlDateTimeFormat,
				componentArgs: { month: 'numeric', day: 'numeric', year: 'numeric' },
				width: 115,
				textAlign: 'left',
				isSortable: true,
				isFixed: '',
				isVisible: true,
				linkRoute: 'businesses.business.lgm-insurance-endorsement',
				linkModelPath: 'insuranceEndorsementId',
			},
			{
				id: '42f6704e-27e0-4068-a3f0-d2ea3c942f9e',
				name: 'Coverage Period',
				valuePath: 'coverageMonth',
				cellComponent: CellComponents.String,
				width: 155,
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '27e1a4b3-f24b-48c8-bd09-90342f08697d',
				name: 'Target',
				valuePath: 'target',
				width: 80,
				cellComponent: CellComponents.IntlNumberFormat,
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'f9ad7b30-17a0-4767-98f1-a0e850f65cbd',
				name: 'Delta Quantity',
				valuePath: 'lgmModelData.delta',
				width: 120,
				cellComponent: CellComponents.NumericFormat,
				componentArgs: {
					maximumFractionDigits: '1',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '519b1f4c-98da-4d97-9bca-dfc2e7323d28',
				name: 'Producer Premium',
				valuePath: 'producerPremiumAmount',
				width: 160,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '0f6d755e-5754-4857-bf4b-f80777a2c802',
				name: 'Gross Margin Guarantee',
				valuePath: 'grossMarginGuarantee',
				width: 200,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '140d7f46-9809-442f-aa52-d82240e3a266',
				name: 'Total Gross Margin',
				valuePath: 'totalExpectedGrossMargin',
				width: 165,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: true,
				isFixed: '',
				isVisible: false,
			},
			{
				id: '42f48acb-a143-478e-8f26-3932dbb528a1',
				name: 'Est. Indemnity',
				valuePath: 'forecastedIndemnity',
				width: 140,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: true,
				isFixed: '',
				isVisible: true,
				isTotaled: true,
			},
			{
				id: 'fac7fb48-da2d-477a-a070-2e2b56f493d4',
				name: 'Net P/L',
				valuePath: 'pnl',
				width: 110,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: true,
				isFixed: '',
				isVisible: true,
				isTotaled: true,
			},
			{
				id: 'fe8cff2e-506b-4e37-9d09-37f9670b226f',
				name: 'Producer',
				valuePath: 'producerName',
				cellComponent: CellComponents.String,
				width: 125,
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: false,
			},
			{
				id: '8d45dbe1-2856-44db-8d8f-62a098d780e6',
				name: 'RMA Type',
				valuePath: 'rmaTypeName',
				width: 125,
				cellComponent: CellComponents.String,
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: false,
			},
			{
				id: '7106931f-4d38-4df7-80c3-f7718bd62440',
				name: 'Deductible',
				valuePath: 'deductibleAmount',
				width: 120,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: true,
				isFixed: '',
				isVisible: false,
			},
			{
				id: '5adc15ee-8888-4ab6-9a23-c2f0c742fe06',
				name: 'Commodity',
				valuePath: 'RmaType.RmaCommodity.commodityName',
				width: 110,
				cellComponent: CellComponents.String,
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: false,
			},
			{
				id: 'e69c8841-e786-4416-bdf3-6dc03b40b986',
				name: 'Expected Feed Cost',
				valuePath: 'expectedFeedCost',
				width: 180,
				cellComponent: CellComponents.IntlNumberFormat,
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: false,
			},
			{
				id: '648e65e8-ddb8-4332-aa13-daea17f9ee3e',
				name: 'Expected Gross Margin',
				valuePath: 'expectedGrossMargin',
				width: 180,
				cellComponent: CellComponents.IntlNumberFormat,
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: false,
			},
			{
				id: 'b627cd44-a182-49b0-96f5-f9d43921aedb',
				name: 'SBM',
				valuePath: 'sbm',
				width: 100,
				cellComponent: CellComponents.IntlNumberFormat,
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: false,
			},
			{
				id: '5284e4f6-37ac-4b8c-b38a-f36b4f3ed36d',
				name: 'State',
				valuePath: 'InsurancePolicy.State.abbreviation',
				cellComponent: CellComponents.String,
				width: 70,
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: false,
			},
			{
				id: 'd0e5bfa6-374f-4635-85a7-bcee81738445',
				name: 'Agent',
				valuePath: 'agentFullName',
				width: 120,
				cellComponent: CellComponents.String,
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: false,
			},
			{
				id: '9e0a24fd-19a9-473b-8432-b9734a737f36',
				name: 'AIP',
				valuePath: 'InsurancePolicy.AIP.name',
				width: 120,
				textAlign: '',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: false,
			},
			{
				id: '11529bbd-d3c1-438e-adc1-72aa55810311',
				name: 'Agency',
				valuePath: 'InsurancePolicy.agencyName',
				width: 200,
				textAlign: 'left',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: false,
			},
			{
				id: '1532321d-0708-4f00-9995-95504f01dbfe',
				name: 'Subsidy',
				valuePath: 'subsidyAmount',
				width: 180,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: true,
				isFixed: '',
				isVisible: false,
			},
		];

		return baseColumns;
	}

	get brokerageRows() {
		const owner = getOwner(this);
		const data = this.model.hedgeStrategy?.data?.HedgeStrategy?.Transactions as TransactionQuery[];

		if (!data) return null;
		return data.map(({ ...transactionData }) => {
			const marketData = this.marketData.getMarketDatum(transactionData.Instrument.PriceInstrument?.barchartSymbol);
			let modeledTransaction: modeledTransaction = {};
			if (transactionData.Instrument.type === 'Future') {
				modeledTransaction = new FutureTransaction(owner, transactionData, null, null);
			}

			if (transactionData.Instrument.type === 'Option') {
				modeledTransaction = new OptionTransaction(owner, transactionData, null, null);
			}

			if (transactionData.Instrument.type === 'Swap') {
				modeledTransaction = new SwapTransaction(owner, transactionData, null, null);
			}

			if (transactionData.Instrument.type === 'Swaption') {
				modeledTransaction = new SwaptionTransaction(owner, transactionData, null, null);
			}

			return {
				...transactionData,
				accountName: transactionData.Account.name,
				Account: modeledTransaction.Account,
				Instrument: modeledTransaction.Instrument,
				marketDataInstrument: transactionData.Instrument.PriceInstrument?.barchartSymbol
					? transactionData.Instrument.PriceInstrument
					: transactionData.Instrument,
				side: modeledTransaction?.side,
				grossPl: modeledTransaction?.grossPl,
				netPl: modeledTransaction?.netPl,
				absQuantityInContracts: modeledTransaction?.absQuantityInContracts,
				commission: modeledTransaction?._commissionTotal,
				fees: modeledTransaction?._feeTotal,
				lastPrice: marketData?.lastPrice,
			};
		});
	}

	get lrpRows() {
		const data = this.model.hedgeStrategy?.data?.HedgeStrategy?.InsuranceEndorsements.filter(
			(endorsement) => endorsement.type === 'Lrp',
		) as lrpEndorsementObj[];

		if (!data) return null;

		return data.map((endorsement) => {
			let lrpModelData: lrpEndorsementObj | LrpInsuranceEndorsementModel | null = null;
			if (this.hedgeStrategy?.Product?.id) {
				lrpModelData = new LrpInsuranceEndorsementModel(getOwner(this), endorsement, this.hedgeStrategy.Product);
			}
			const { ...endorsementObj } = endorsement;
			return {
				...endorsementObj,
				insuranceEndorsementId: endorsement.id,
				producerName: endorsement.InsurancePolicy.producerName,
				coverageEndDate: endorsementObj.coverageEndDate,
				price: endorsementObj.coveragePrice,
				commodityName: endorsementObj.RmaCommodity?.commodityName,
				headCount: endorsementObj.headCount,
				insuredValue: endorsementObj.insuredValue,
				totalPremium: endorsement.totalPremiumAmount,
				pnl: endorsement.pnl,
				salesEffectiveDate: endorsement.salesEffectiveDate,
				state: endorsement.InsurancePolicy.State.abbreviation,
				type: endorsement.type,
				targetWeightCwt: endorsementObj.targetWeightCwt,
				lengthInWeeks: endorsementObj.lengthInWeeks,
				subsidyAmount: endorsementObj.subsidyAmount,
				producerPremiumAmount: endorsementObj.producerPremiumAmount,
				indemnity: endorsementObj.indemnity,
				policyNumber: endorsement.InsurancePolicy.policyNumber,
				agencyName: endorsement.InsurancePolicy.agencyName,
				aipName: endorsement.InsurancePolicy.AIP.name,
				agentFullName: endorsement.InsurancePolicy.agentFullName,
				lrpModelData: lrpModelData,
			};
		});
	}

	get lgmRows() {
		const data = this.model.hedgeStrategy?.data?.HedgeStrategy?.InsuranceEndorsements.filter((endorsement) => endorsement.type === 'Lgm');

		if (!data) return null;

		const endorsements: Array<LgmInsuranceEndorsementByCoverageMonth> = [];

		data.map((endorsement: LgmInsuranceEndorsementByCoverageMonth) => {
			let lgmModelData: LgmInsuranceEndorsementByCoverageMonth | LgmInsuranceEndorsementModel | null = null;
			if (this.hedgeStrategy?.Product?.id) {
				lgmModelData = new LgmInsuranceEndorsementModel(getOwner(this), endorsement, this.hedgeStrategy.Product);
			}
			const { perMonthData, ...endorsementObj } = endorsement;
			//breaking endorsement up into separate row items based on coverage month and `perMonthData`. This is because the users expects to see the endorsement broken up by coverage month and there can be multiple months on a single LGM endorsement.

			const coverageMonthCount = endorsement?.coverageMonths?.length;

			const firstCoverageMonth =
				Array.isArray(endorsement.coverageMonths) && endorsement.coverageMonths?.length > 0 ? endorsement.coverageMonths[0] : null;

			const lastCoverageMonth =
				Array.isArray(endorsement?.coverageMonths) && endorsement.coverageMonths?.length > 1
					? endorsement.coverageMonths[endorsement.coverageMonths.length - 1]
					: null;

			const targetSum = endorsement?.perMonthData?.reduce((acc, { target }) => acc + (target ?? 0), 0);

			const cornSum = endorsement?.perMonthData?.reduce((acc, { corn }) => acc + (corn ?? 0), 0);

			const sbmSum = endorsement?.perMonthData?.reduce((acc, { sbm }) => acc + (sbm ?? 0), 0);

			const expectedFeedCostSum = endorsement?.perMonthData?.reduce((acc, { expectedFeedCost }) => acc + (expectedFeedCost ?? 0), 0);

			const expectedGrossMarginSum = endorsement?.perMonthData?.reduce(
				(acc, { expectedGrossMargin }) => acc + (expectedGrossMargin ?? 0),
				0,
			);

			const pnl = endorsement?.pnl;
			const newEndorsementObj = {
				...endorsementObj,
				isCollapsed: true,
				insuranceEndorsementId: endorsement?.id,
				target: targetSum,
				corn: cornSum,
				sbm: sbmSum,
				expectedFeedCost: expectedFeedCostSum,
				expectedGrossMargin: expectedGrossMarginSum,
				rmaTypeName: endorsementObj?.RmaType?.typeName,
				producerPremiumAmount: endorsement?.producerPremiumAmount,
				totalExpectedGrossMargin: endorsement?.totalExpectedGrossMargin,
				pnl,
				producerName: endorsement?.InsurancePolicy?.producerName,
				salesEffectiveDate: endorsement?.salesEffectiveDate,
				forecastedIndemnity: endorsement?.forecastedIndemnity,
				grossMarginGuarantee: endorsement?.grossMarginGuarantee,
				deductibleAmount: endorsement?.deductibleAmount,
				agentFullName: endorsement?.InsurancePolicy?.agentFullName,
				perMonthData: endorsement?.perMonthData,
				coverageMonth: `${
					firstCoverageMonth
						? intlDateTimeFormat({
								value: firstCoverageMonth,
								month: 'short',
								year: 'numeric',
							})
						: '-'
				} thru ${
					lastCoverageMonth
						? intlDateTimeFormat({
								value: lastCoverageMonth,
								month: 'short',
								year: 'numeric',
							})
						: '-'
				}`,
				lgmModelData: lgmModelData,
			};
			const childrenArray: PerMonthData[] = [];
			if (coverageMonthCount) {
				for (let i = 0; i < coverageMonthCount; i++) {
					const child: PerMonthData | null | undefined = {};
					const { coverageMonths, perMonthData } = endorsement ? endorsement : { coverageMonths: [], perMonthData: [] };

					child.coverageMonth = coverageMonths?.length
						? intlDateTimeFormat({
								value: coverageMonths[i],
								month: 'short',
								year: 'numeric',
							})
						: null;
					child.monthIndex = perMonthData?.length ? perMonthData[i].monthIndex : null;
					child.target = perMonthData?.length ? perMonthData[i].target : null;
					child.corn = perMonthData?.length ? perMonthData[i].corn : null;
					child.expectedFeedCost = perMonthData?.length ? perMonthData[i].expectedFeedCost : null;
					child.expectedGrossMargin = perMonthData?.length ? perMonthData[i].expectedGrossMargin : null;
					child.sbm = perMonthData?.length ? perMonthData[i].sbm : null;

					childrenArray.push(child);
				}
			}

			newEndorsementObj.children = childrenArray;
			endorsements.push(newEndorsementObj);
		});
		return endorsements;
	}

	get hedgeStrategy() {
		return this.model.hedgeStrategy.data?.HedgeStrategy;
	}

	get hedgeStrategyHistoricalPnlData() {
		return this.hedgeStrategy?.HedgeStrategyHistoricalPnls;
	}

	get hsHistoricalPnlRawData() {
		const dataObj: HistoricalPnlDataObj = { strategyPnl: [], brokeragePnl: [], insurancePnl: [] };

		const data: { [key: string]: { month: string; strategyPnl: number; brokeragePnl: number; insurancePnl: number } } = {};

		if (!this.hedgeStrategyHistoricalPnlData) return null;

		this.hedgeStrategyHistoricalPnlData.forEach((obj) => {
			const monthKey = DateTime.fromISO(obj.date).toFormat('MMM dd yyyy');
			if (!data[monthKey]) {
				data[monthKey] = {
					month: monthKey,
					strategyPnl: this.strategyPl(obj),
					brokeragePnl: obj.positionRealizedPnl + obj.positionUnrealizedPnl,
					// Net premium re-added at Josh's request.
					insurancePnl: obj.insuranceRealizedPnl + obj.insuranceUnrealizedPnl + obj.insuranceNetPremium,
				};
			} else {
				data[monthKey].strategyPnl += this.strategyPl(obj);
				data[monthKey].brokeragePnl += obj.positionRealizedPnl + obj.positionUnrealizedPnl;
				data[monthKey].insurancePnl += obj.insuranceRealizedPnl + obj.insuranceUnrealizedPnl + obj.insuranceNetPremium;
			}
		});
		const months = Object.values(data);
		months.forEach((month) => {
			dataObj.strategyPnl.push(month.strategyPnl);
			dataObj.brokeragePnl.push(month.brokeragePnl);
			dataObj.insurancePnl.push(month.insurancePnl);
		});

		return { months, dataObj };
	}

	get hsHistoricalPnlChartData(): ChartData<'line'> {
		const datasets: ChartDataset<'line', (number | null)[]>[] = this.hsHistoricalPnlRawData
			? [
					{
						label: 'Strategy P/L',
						data: this.hsHistoricalPnlRawData?.dataObj.strategyPnl,
						borderColor: getComputedStyle(document.documentElement).getPropertyValue('--brand-interactive-blue-70'),
						backgroundColor: getComputedStyle(document.documentElement).getPropertyValue('--brand-interactive-blue-70'),
						pointRadius: 0,
						tension: 0.1,
					},
					{
						label: 'Brokerage P/L',
						data: this.hsHistoricalPnlRawData?.dataObj.brokeragePnl,
						borderColor: getComputedStyle(document.documentElement).getPropertyValue('--brand-lime-40'),
						backgroundColor: getComputedStyle(document.documentElement).getPropertyValue('--brand-lime-40'),
						pointRadius: 0,
						tension: 0.1,
					},
					// eventually we will have separate LRP and LGM data
					{
						label: 'Insurance P/L',
						data: this.hsHistoricalPnlRawData?.dataObj.insurancePnl,
						borderColor: getComputedStyle(document.documentElement).getPropertyValue('--brand-orange-40'),
						backgroundColor: getComputedStyle(document.documentElement).getPropertyValue('--brand-orange-40'),
						pointRadius: 0,
						tension: 0.1,
					},
					// {
					// 	label: 'LGM',
					// 	data: this.hsHistoricalPnlRawData?.LGM,
					// 	borderColor: getComputedStyle(document.documentElement).getPropertyValue('--brand-lemon-40'),
					// 	backgroundColor: getComputedStyle(document.documentElement).getPropertyValue('--brand-lemon-40'),
					// 	pointRadius: 0,
					// 	tension: 0.1,
					// },
				]
			: [];

		return {
			labels: this.chartLabels,
			datasets,
		};
	}

	get hsHistoricalPnlChartOptions(): ChartOptions<'line'> {
		const tooltipOptions: CustomTooltipOptions = {
			titleFormatter: (val: string) => {
				return val;
			},
			valueFormatter: (val: number) => {
				return new Intl.NumberFormat('en-US', {
					style: 'currency',
					currency: 'USD',
				}).format(val);
			},
		};
		return {
			spanGaps: true,
			maintainAspectRatio: false,
			responsive: true,
			interaction: {
				intersect: false,
				mode: 'index',
			},
			layout: {
				padding: {
					top: 12,
					left: 12,
					right: 12,
				},
			},
			scales: {
				y: {
					grid: {
						display: true,
					},
					ticks: {
						autoSkip: true,
						font: {
							size: 12,
						},
						callback: (val) => {
							if (typeof val === 'string') {
								return val;
							} else {
								return new Intl.NumberFormat('en-US', {
									style: 'currency',
									currency: 'USD',
									notation: 'compact',
								}).format(val);
							}
						},
						color: getComputedStyle(document.documentElement).getPropertyValue('brand-gray-60'),
					},
				},
				x: {
					stacked: true,
					ticks: {
						callback: function (_tickValue, index) {
							return this.getLabelForValue(index);
						},
					},
					grid: {
						display: false,
					},
				},
			},
			plugins: {
				legend: {
					display: false,
				},
				tooltip: {
					enabled: false,
					external: getCustomTooltip(tooltipOptions),
				},
			},
		};
	}

	get chartLabels() {
		const labels: string[] = [];

		if (!this.hsHistoricalPnlRawData) return labels;
		this.hsHistoricalPnlRawData?.months?.forEach((obj) => {
			labels.push(obj.month);
		});
		return labels;
	}

	get hsHistoricalPnlChartPlugins() {
		return [
			{
				afterUpdate: getCustomLegend('strategy-historical-pl-chart-id', 'strategy-historical-pl-legend-id'),
			},
		];
	}

	get modelLastUpdatedAt() {
		try {
			return DateTime.fromISO(this.model.lastUpdatedAt).toLocaleString(DateTime.DATETIME_FULL);
		} catch {
			return '';
		}
	}

	get isNaturallyLong() {
		const hasLrp = this.insuranceEndorsements?.filterBy('type', TypeOfInsuranceEndorsement.Lrp).length || false;

		// If an LRP endorsement exists, the strategy is naturally long.
		if (hasLrp) {
			return true;
		}

		const hasLgm = this.insuranceEndorsements?.filterBy('type', TypeOfInsuranceEndorsement.Lgm).length || false;
		const isCorn = this.model.hedgeStrategy.data?.Product?.slug === 'grain-corn';
		const isSBM = this.model.hedgeStrategy.data?.Product?.slug === 'grain-soybean-meal';

		// If an LGM endorsement exists and the product isn't corn or SBM, the strategy is naturally long.
		if (hasLgm && !isCorn && !isSBM) {
			return true;
		}

		const netCallQuantity = this.transactions.reduce((acc, transaction) => {
			if (transaction.Instrument.type === 'Option') {
				if ((transaction.Instrument as Option).optionType === TypeOfOption.Call) {
					return acc + transaction.contractQuantity;
				}
			}
			return acc;
		}, 0);

		const netPutQuantity = this.transactions.reduce((acc, transaction) => {
			if (transaction.Instrument.type === 'Option') {
				if ((transaction.Instrument as Option).optionType === TypeOfOption.Put) {
					return acc + transaction.contractQuantity;
				}
			}
			return acc;
		}, 0);

		// If the net quantity of puts is greater than the net quantity of calls, the strategy is naturally long.
		if (netPutQuantity > netCallQuantity) {
			return true;
		}

		// If the net quantity of calls is greater than the net quantity of puts, the strategy is naturally short.
		if (netCallQuantity > netPutQuantity) {
			return false;
		}

		const netFutureQuantity = this.transactions.reduce((acc, transaction) => {
			if (transaction.Instrument.type === 'Future') {
				return acc + transaction.contractQuantity;
			}
			return acc;
		}, 0);

		if (netFutureQuantity < 0) {
			return true;
		}

		if (netFutureQuantity > 0) {
			return false;
		}

		console.log('Unable to determine if strategy is naturally long or short.  Defaulting to long.');
		return true;
	}

	// hedge pl chart
	get netHedgePriceRawData() {
		const dataObj: NetHedgePrice = { 'Market Price': [], 'Net Hedge Price': [] };
		dataObj['Market Price'] = this.pricePoints.map((price) => ({ x: price, y: price }));

		if (this.isNaturallyLong) {
			// If naturally long, you're trying to increase the hedge price, so the net hedge price is the market price plus the hedge price P/L.
			dataObj['Net Hedge Price'] = this.pricePoints.map((price, idx) => ({ x: price, y: price + this.hedgePricePl[idx] ?? 0 }));
		} else {
			dataObj['Net Hedge Price'] = this.pricePoints.map((price, idx) => ({ x: price, y: price - this.hedgePricePl[idx] ?? 0 }));
		}

		return dataObj;
	}

	get netHedgePriceChartData(): ChartData<'line'> {
		//@ts-ignore DGC: We need to update this type.
		const datasets: ChartDataset<'line', (number | null)[]>[] = this.netHedgePlRawData
			? [
					{
						label: 'Market Price',
						data: this.netHedgePriceRawData['Market Price'],
						borderColor: getComputedStyle(document.documentElement).getPropertyValue('--brand-interactive-blue-70'),
						backgroundColor: getComputedStyle(document.documentElement).getPropertyValue('--brand-interactive-blue-70'),
						pointRadius: 0,
						tension: 0.1,
					},
					{
						label: 'Net Hedge Price',
						data: this.netHedgePriceRawData['Net Hedge Price'],
						borderColor: getComputedStyle(document.documentElement).getPropertyValue('--brand-lime-40'),
						backgroundColor: getComputedStyle(document.documentElement).getPropertyValue('--brand-lime-40'),
						pointRadius: 0,
						tension: 0.1,
					},
				]
			: [];

		return {
			datasets,
		};
	}

	//
	//	Scenarios
	//

	get productId() {
		return this.model.hedgeStrategy.data?.HedgeStrategy?.Product?.id;
	}

	get tickValue() {
		return this.model.hedgeStrategy.data?.HedgeStrategy?.Product?.StandardProductLotSpecification.tickValue;
	}

	get fractionDigits() {
		return this.model.hedgeStrategy.data?.HedgeStrategy?.Product?.MostCurrentFuture?.SymbolGroup.fractionDigits || 0;
	}

	get mostCurrentBarchartSymbol() {
		return this.model.hedgeStrategy.data?.HedgeStrategy?.Product?.MostCurrentFuture?.barchartSymbol;
	}

	get transactions() {
		return this.model.hedgeStrategy.data?.HedgeStrategy?.Transactions as TransactionQuery[];
	}

	get insuranceEndorsements() {
		return this.model.hedgeStrategy.data?.HedgeStrategy?.InsuranceEndorsements;
	}

	get brokerageTransactionInstruments() {
		return this.transactions.map((transaction) => {
			return transaction.Instrument;
		});
	}

	get inflectionPoints() {
		//@ts-ignore
		const brokerageOptions = this.brokerageTransactionInstruments.filter((instrument): instrument is Option | Swaption => {
			return instrument.type === TypeOfInstrument.Option || instrument.type === TypeOfInstrument.Swaption;
		});

		const brokerageStrikes = brokerageOptions.map((option) => {
			return option.strike;
		});

		const lrpStrikes =
			this.insuranceEndorsements?.filterBy('type', TypeOfInsuranceEndorsement.Lrp).map((endorsement: LrpInsuranceEndorsement) => {
				return endorsement.coveragePrice;
			}) || [];

		// Include most recent close price if available.  This will handle most LGM-only use cases.
		let currentPrice = undefined;
		if (this.mostCurrentBarchartSymbol) {
			const marketDatum = this.marketData.getMarketDatum(this.mostCurrentBarchartSymbol);
			currentPrice = marketDatum?.close ?? marketDatum?.previousClose;
		}

		const points: number[] = [currentPrice, ...brokerageStrikes, ...lrpStrikes]
			.filter((strike): strike is number => strike !== undefined)
			.uniq();
		return points;
	}

	// This should be used to get the Net Price in the Insurance Overview.
	get currentPrice() {
		if (this.marketData && this.mostCurrentBarchartSymbol) {
			return this.marketData.getEODPrice(this.mostCurrentBarchartSymbol);
		} else {
			return null;
		}
	}

	// This should be every price between the maximum and minimum price in the inflectionPoints array, as well as a buffer of 2 over the max and under the min.
	// At minimum, it should contain minNumberOfPrices.
	get pricePoints() {
		const points: number[] = [];
		const minNumberOfPrices = 200;
		if (!this.tickValue) return points;
		// ternary to support the case where there are no inflection points as with LGM
		const naturalMinPrice = this.inflectionPoints.length ? Math.min(...this.inflectionPoints) : 0;
		const naturalMaxPrice = this.inflectionPoints.length ? Math.max(...this.inflectionPoints) : 0;
		const naturalTickRange = (naturalMaxPrice - naturalMinPrice) / this.tickValue;
		let bufferTicks = Math.ceil(naturalTickRange * 0.1);
		if (naturalTickRange < minNumberOfPrices) {
			bufferTicks = Math.ceil((minNumberOfPrices - naturalTickRange) / 2);
		}

		const minPrice = naturalMinPrice - bufferTicks * this.tickValue * 4;
		const maxPrice = naturalMaxPrice + bufferTicks * this.tickValue * 4;

		points.push(minPrice, maxPrice, ...this.inflectionPoints);
		return points.sort((a, b) => a - b);
	}

	get pointValue() {
		return this.model.hedgeStrategy.data?.HedgeStrategy?.Product?.StandardProductLotSpecification.pointValue || 1;
	}

	// Calculate P/L for each price point for each brokerage transaction
	get brokeragePLs() {
		return this.pricePoints.map((price) => {
			return this.transactions.reduce((total, transaction) => {
				let plPerPoint = 0;
				let premium = 0;
				const pointValue = this.pointValue;
				if (transaction.Instrument.type === TypeOfInstrument.Option || transaction.Instrument.type === TypeOfInstrument.Swaption) {
					const option = transaction.Instrument as Option | Swaption;
					premium = transaction.contractQuantity * transaction.price * pointValue;
					if (option.optionType === TypeOfOption.Call) {
						plPerPoint = transaction.contractQuantity * Math.max(0, price - option.strike);
					} else {
						plPerPoint = transaction.contractQuantity * Math.max(0, option.strike - price);
					}
				}

				if (transaction.Instrument.type === TypeOfInstrument.Future || transaction.Instrument.type === TypeOfInstrument.Swap) {
					plPerPoint = transaction.contractQuantity * (price - transaction.price);
				}

				return plPerPoint * pointValue - premium + total;
			}, 0);
		});
	}

	get lrpInsuranceEndorsements() {
		return this.insuranceEndorsements?.filterBy('type', TypeOfInsuranceEndorsement.Lrp);
	}

	get lrpInsuranceScenarioVariables(): Query_insuranceScenarioPricingForFixedPriceSetsArgs {
		return {
			input: {
				//@ts-ignore
				fixedPriceSets: this.pricePoints.map((price) => {
					return {
						fixedPricesByProduct: {
							price: price ?? 0,
							productId: this.productId,
						},
						identifier: price ? price.toString() : '0',
					};
				}),
				/** A filter used to determine the endorsements for which P/L values will be calculated. This filter will be applied in addition to the `scopeId`. If more than 1000 endorsements are returned by the filter, an error will be thrown. */
				insuranceEndorsementFilter: {
					id: {
						in: this.lrpInsuranceEndorsements?.map((endorsement) => endorsement.id) || [],
					},
				},
			},
		};
	}

	rawLrpInsurancePls = useQuery<insuranceScenarioQuery, Query_insuranceScenarioPricingForFixedPriceSetsArgs>(this, () => [
		INSURANCE_SCENARIO_PRICING,
		{
			variables: this.lrpInsuranceScenarioVariables,
		},
	]);

	get lrpInsurancePls() {
		return (
			this.rawLrpInsurancePls.data?.insuranceScenarioPricingForFixedPriceSets.map((insuranceScenario) => {
				return insuranceScenario.totalPnl;
			}) || []
		);
	}

	get lgmInsuranceEndorsements() {
		return this.insuranceEndorsements?.filterBy('type', TypeOfInsuranceEndorsement.Lgm);
	}

	get lgmInsuranceScenarioVariables(): Query_insuranceScenarioPricingForFixedPriceSetsArgs {
		return {
			input: {
				//@ts-ignore
				fixedPriceSets: this.pricePoints.map((price) => {
					return {
						fixedPricesByProduct: {
							price: price ?? 0,
							productId: this.productId,
						},
						identifier: price ? price.toString() : '0',
					};
				}),
				/** A filter used to determine the endorsements for which P/L values will be calculated. This filter will be applied in addition to the `scopeId`. If more than 1000 endorsements are returned by the filter, an error will be thrown. */
				insuranceEndorsementFilter: {
					id: {
						in: this.lgmInsuranceEndorsements?.map((endorsement) => endorsement.id) || [],
					},
				},
			},
		};
	}

	rawLgmInsurancePls = useQuery<insuranceScenarioQuery, Query_insuranceScenarioPricingForFixedPriceSetsArgs>(this, () => [
		INSURANCE_SCENARIO_PRICING,
		{
			variables: this.lgmInsuranceScenarioVariables,
		},
	]);

	get lgmInsurancePls() {
		return (
			this.rawLgmInsurancePls.data?.insuranceScenarioPricingForFixedPriceSets?.map((insuranceScenario) => {
				return insuranceScenario.totalPnl;
			}) || []
		);
	}

	get netPls() {
		// Sum lrpInsurancePls and brokeragePLs
		const netPls: number[] = [];
		this.pricePoints.forEach((_, i) => {
			netPls.push(this.lrpInsurancePls[i] + this.brokeragePLs[i] + this.lgmInsurancePls[i]);
		});

		return netPls;
	}

	get hedgeQuantity() {
		const lrpQuantity =
			this.lrpInsuranceEndorsements?.reduce(
				(acc, endorsement: LrpInsuranceEndorsement) => acc + endorsement.headCount * endorsement.targetWeightCwt * 100,
				0,
			) || 0;

		let lgmQuantity = 0;

		// LGM logic for Lean Hog strategies
		// We need to figure out a better source for this data, especially with the use of the magic value.
		// Can the API surface it?
		if (this.model.hedgeStrategy.data?.Product?.slug === 'livestock-lean-hogs') {
			const lgmSwineTargetWeight = 260;
			lgmQuantity =
				this.lgmInsuranceEndorsements?.reduce(
					(acc, endorsement: LgmInsuranceEndorsement) => acc + endorsement.totalTarget * lgmSwineTargetWeight,
					0,
				) || 0;
		}

		// LGM Type Codes
		//Live Cattle
		//807 (calf finishing),
		//808 (yearling finishing),

		//Feeder Cattle without Unborn Steers & Heifers (I'm uncertain how Unborn Steers & Heifers affects the quantity of feeder cattle.)
		//809 (Steers Weight 1)
		//810 (Steers Weight 2)
		//811 (Heifers Weight 1)
		//812 (Heifers Weight 2)
		//813 (Brahman Weight 1)
		//814 (Brahman Weight 2)
		//815 (Dairy Weight 1)
		//816 (Dairy Weight 2)
		//817 (Unborn Steers & Heifers) - not used just for reference incase it is needed in the future
		// LGM logic for Feeder Cattle
		// Source: https://extension.missouri.edu/media/wysiwyg/Extensiondata/Pub/pdf/agguides/agecon/g00461.pdf

		if (this.model.hedgeStrategy.data?.Product?.slug === 'livestock-feeder-cattle') {
			const yearlingFinishingTargetWeight = 750;
			const calfFinishingTargetWeight = 550;
			lgmQuantity =
				this.lgmInsuranceEndorsements?.reduce((acc, endorsement: LgmInsuranceEndorsement) => {
					// Type Code 810, 812, 814, 816 should be Yearling
					if (
						endorsement.RmaType.typeCode == '810' ||
						endorsement.RmaType.typeCode == '814' ||
						endorsement.RmaType.typeCode == '812' ||
						endorsement.RmaType.typeCode == '816'
					) {
						return acc + endorsement.totalTarget * yearlingFinishingTargetWeight;
					}

					// Type Code 809m 811, 813, 815 should be Calf
					if (
						endorsement.RmaType.typeCode == '809' ||
						endorsement.RmaType.typeCode == '811' ||
						endorsement.RmaType.typeCode == '813' ||
						endorsement.RmaType.typeCode == '815'
					) {
						return acc + endorsement.totalTarget * calfFinishingTargetWeight;
					}
					console.error('We found an LGM endorsement without a matching type code.');
					return acc;
				}, 0) || 0;
		}

		// LGM logic for Live Cattle
		// Source: https://extension.missouri.edu/media/wysiwyg/Extensiondata/Pub/pdf/agguides/agecon/g00461.pdf
		if (this.model.hedgeStrategy.data?.Product?.slug === 'livestock-live-cattle') {
			const yearlingFinishingTargetWeight = 1250;
			const calfFinishingTargetWeight = 1150;
			lgmQuantity =
				this.lgmInsuranceEndorsements?.reduce((acc, endorsement: LgmInsuranceEndorsement) => {
					// Type Code 808 should be Yearling
					if (endorsement.RmaType.typeCode == '808') {
						return acc + endorsement.totalTarget * yearlingFinishingTargetWeight;
					}

					// Type Code 807 should be Calf
					if (endorsement.RmaType.typeCode == '807') {
						return acc + endorsement.totalTarget * calfFinishingTargetWeight;
					}
					console.error('We found an LGM endorsement without a matching type code.');
					return acc;
				}, 0) || 0;
		}

		const brokerageToInclude = this.transactions.filter(
			(transaction) =>
				transaction.Instrument.type == TypeOfInstrument.Future ||
				transaction.Instrument.type == TypeOfInstrument.Swap ||
				(transaction.Instrument.type == TypeOfInstrument.Option && transaction.contractQuantity > 0) ||
				(transaction.Instrument.type == TypeOfInstrument.Swaption && transaction.contractQuantity > 0),
		);
		const brokerageQuantity = brokerageToInclude.reduce((acc, transaction: Transaction) => acc + transaction.unitQuantity, 0);

		const lotSize = this.model.hedgeStrategy.data?.HedgeStrategy?.Product?.StandardProductLotSpecification?.lotSize || 1;

		return (lrpQuantity + brokerageQuantity + lgmQuantity) / lotSize;
	}

	get hedgePricePl() {
		const pointValue = this.model.hedgeStrategy.data?.HedgeStrategy?.Product?.StandardProductLotSpecification?.pointValue || 1;
		return this.netPls.map((pl) => pl / pointValue / this.hedgeQuantity);
	}

	// hedge pl chart
	get netHedgePlRawData() {
		const dataObj: NetHedgePl = { Brokerage: [], LRP: [], LGM: [], 'Net Hedge': [] };

		dataObj.Brokerage = this.brokeragePLs.map((pl, idx) => ({ x: this.pricePoints[idx], y: pl }));
		dataObj.LRP = this.lrpInsurancePls.map((pl, idx) => ({ x: this.pricePoints[idx], y: pl }));
		dataObj.LGM = this.lgmInsurancePls.map((pl, idx) => ({ x: this.pricePoints[idx], y: pl }));
		dataObj['Net Hedge'] = this.netPls.map((pl, idx) => ({ x: this.pricePoints[idx], y: pl }));

		return dataObj;
	}

	get netHedgePlChartData(): ChartData<'line'> {
		//@ts-ignore DGC: We need to update this type.
		let datasets: ChartDataset<'line', (number | null)[]>[] = this.netHedgePlRawData
			? [
					{
						label: 'Brokerage',
						data: this.netHedgePlRawData.Brokerage,
						borderColor: getComputedStyle(document.documentElement).getPropertyValue('--brand-interactive-blue-70'),
						backgroundColor: getComputedStyle(document.documentElement).getPropertyValue('--brand-interactive-blue-70'),
						pointRadius: 0,
						tension: 0.1,
					},
					{
						label: 'LRP',
						data: this.netHedgePlRawData.LRP,
						borderColor: getComputedStyle(document.documentElement).getPropertyValue('--brand-lime-40'),
						backgroundColor: getComputedStyle(document.documentElement).getPropertyValue('--brand-lime-40'),
						pointRadius: 0,
						tension: 0.1,
					},
					{
						label: 'LGM',
						data: this.netHedgePlRawData.LGM,
						borderColor: getComputedStyle(document.documentElement).getPropertyValue('--brand-lemon-40'),
						backgroundColor: getComputedStyle(document.documentElement).getPropertyValue('--brand-lemon-40'),
						pointRadius: 0,
						tension: 0.1,
					},
					{
						label: 'Net Hedge',
						data: this.netHedgePlRawData['Net Hedge'],
						borderColor: getComputedStyle(document.documentElement).getPropertyValue('--brand-teal-60'),
						backgroundColor: getComputedStyle(document.documentElement).getPropertyValue('--brand-teal-60'),
						pointRadius: 0,
						tension: 0.1,
					},
				]
			: [];

		if (!this.displayLrp) {
			datasets = datasets.filter((dataset) => dataset.label !== 'LRP');
		}

		if (!this.displayLgm) {
			datasets = datasets.filter((dataset) => dataset.label !== 'LGM');
		}

		return {
			datasets,
		};
	}

	@action
	updateHsHistoricalPnlChart(chart: Chart<'line'>, _data: unknown) {
		if (!this.hsHistoricalPnlRawData) return;
		chart.data = this.hsHistoricalPnlChartData;
		chart.update('none');
	}

	strategyPl(obj: HedgeStrategyHistoricalPnl) {
		const formattedInsurancePnl = obj.insuranceRealizedPnl + obj.insuranceUnrealizedPnl + obj.insuranceNetPremium;
		const formattedBrokeragePnl = obj.positionUnrealizedPnl + obj.positionRealizedPnl;
		return formattedInsurancePnl + formattedBrokeragePnl;
	}
}
