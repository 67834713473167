import Route from '@ember/routing/route';
import { RouteQueryParam } from '@ember/routing/types';
import { tracked } from '@glimmer/tracking';
import { gql, useQuery } from 'glimmer-apollo';
import { DateTime } from 'luxon';
import {
	AllocatedForecastedHedgedAndCappedVolumeFilterDTO,
	CurrentAllocationPositionFilterDTO,
	CustomerEntity,
	DerivedDrpEndorsementFilterDTO,
	EntityAllocatedExposureRatioFilterDTO,
	ForecastedHedgedAndCappedVolumeFilterDTO,
	ForecastedMilkUtilizationFilterDTO,
	SwineLivestockPopulationChangeFilterDTO,
	TypeOfAllocatedHedge,
	TypeOfHedge,
	TypeOfInstrument,
	TypeOfLedgerCategory,
	TypeOfLivestockPopulationChangeReason,
	TypeOfOption,
} from 'vault-client/types/graphql-types';
import { GET_PIGS_DASHBOARD, GetPigsDashboardQuery, GetPigsDashboardQueryArgs } from './pig-dashboard';
import { parseISO, subWeeks, startOfWeek, formatISO } from 'date-fns';
import {
	GetDairyDashboardQuery,
	GetDairyDashboardQueryArgs,
	GetInsuranceEndorsementAllocationRatiosQuery,
	GetInsuranceEndorsementAllocationRatiosQueryArgs,
} from 'vault-client/routes/businesses/business/dashboard';
import MarketDataService from 'vault-client/services/market-data';
import { service } from '@ember/service';

interface ModelParams {
	startDate: string;
	endDate: string;
	// In the future, this type param could be replaced by checking business roles once we figure out a way to handle when a business has multiple business roles
	type: string;
}

const GET_DAIRY_DASHBOARD = gql`
	query Customer(
		$customerId: String!
		$startDate: String!
		$endDate: String!
		$classiiiExposureWhere: EntityAllocatedExposureRatioFilterDTO!
		$classivExposureWhere: EntityAllocatedExposureRatioFilterDTO!
		$cheeseExposureWhere: EntityAllocatedExposureRatioFilterDTO!
		$butterExposureWhere: EntityAllocatedExposureRatioFilterDTO!
		$nonfatExposureWhere: EntityAllocatedExposureRatioFilterDTO!
		$dryWheyExposureWhere: EntityAllocatedExposureRatioFilterDTO!
		$positionWhere: CurrentAllocationPositionFilterDTO
		$dairyPositionWhere: CurrentAllocationPositionFilterDTO
		$feedPositionWhere: CurrentAllocationPositionFilterDTO
		$aggregateDairyBrokerageHedgedValuesWhere: AllocatedForecastedHedgedAndCappedVolumeFilterDTO
		$typeOfLedgerCategory: TypeOfLedgerCategory
		$futuresStartDate: String
		$futuresEndDate: String
	) {
		Customer(id: $customerId) {
			id
			name
			businessRoles
			Locations {
				id
				County {
					id
					classIDifferential
					name
				}
			}
		}
		ClassiiiExposure: EntityAllocatedExposureRatios(where: $classiiiExposureWhere) {
			date
			totalPercentVolumeHedged
			netProductionExposure
			totalVolumeHedged
		}
		ClassivExposure: EntityAllocatedExposureRatios(where: $classivExposureWhere) {
			date
			totalPercentVolumeHedged
			netProductionExposure
			totalVolumeHedged
		}
		CheeseExposure: EntityAllocatedExposureRatios(where: $cheeseExposureWhere) {
			date
			totalPercentVolumeHedged
			netProductionExposure
			totalVolumeHedged
		}
		ButterExposure: EntityAllocatedExposureRatios(where: $butterExposureWhere) {
			date
			totalPercentVolumeHedged
			netProductionExposure
			totalVolumeHedged
		}
		NonfatExposure: EntityAllocatedExposureRatios(where: $nonfatExposureWhere) {
			date
			totalPercentVolumeHedged
			netProductionExposure
			totalVolumeHedged
		}
		DryWheyExposure: EntityAllocatedExposureRatios(where: $dryWheyExposureWhere) {
			date
			totalPercentVolumeHedged
			netProductionExposure
			totalVolumeHedged
		}
		ForecastedMilkProductionByMonths(where: { date: { gte: $startDate, lte: $endDate } }, orderBy: { date: Asc }, scopeId: $customerId) {
			id
			numberOfCows
			grossProduction
			date
			classiUtilization
			classiiUtilization
			classiiiUtilization
			classivUtilization
			grossClassiProduction
			grossClassiiProduction
			grossClassiiiProduction
			grossClassivProduction
			grossProteinProduction
			grossButterfatProduction
			grossOtherSolidsProduction
			UsdaActualMilkPrice {
				id
				classiPrice
				classiiPrice
				classiiiPrice
				classivPrice
			}
			Entity {
				id
				type
				... on LocationEntity {
					currentBasis
					County {
						id
						classIDifferential
					}
				}
			}
		}
		AggregateCurrentAllocationPositions(
			scopeId: $customerId
			calc: { sum: { grossPnl: true } }
			groupBy: { effectiveHedgeDate: true }
			where: $positionWhere
		) {
			effectiveHedgeDate
			sum {
				grossPnl
			}
		}
		AggregateFeedPositions: AggregateCurrentAllocationPositions(
			scopeId: $customerId
			calc: { sum: { grossPnl: true } }
			groupBy: { effectiveHedgeDate: true }
			where: $feedPositionWhere
		) {
			effectiveHedgeDate
			sum {
				grossPnl
			}
		}
		AggregateDairyPositions: AggregateCurrentAllocationPositions(
			scopeId: $customerId
			calc: { sum: { grossPnl: true } }
			groupBy: { effectiveHedgeDate: true }
			where: $dairyPositionWhere
		) {
			effectiveHedgeDate
			sum {
				grossPnl
			}
		}
		ClassIIIFutures: Futures(
			where: {
				AND: [
					{ displayExpiresAt: { gte: $futuresStartDate } }
					{ displayExpiresAt: { lte: $futuresEndDate } }
					{ isStandardContractSize: { equals: true } }
				]
				Product: { slug: { equals: "us-dairy-class-iii" } }
			}
		) {
			id
			displayExpiresAt
			barchartSymbol
			SymbolGroup {
				displayFactor
			}
		}
		ClassIVFutures: Futures(
			where: {
				AND: [
					{ displayExpiresAt: { gte: $futuresStartDate } }
					{ displayExpiresAt: { lte: $futuresEndDate } }
					{ isStandardContractSize: { equals: true } }
				]
				Product: { slug: { equals: "us-dairy-class-iv" } }
			}
		) {
			id
			displayExpiresAt
			barchartSymbol
			SymbolGroup {
				displayFactor
			}
		}
		ButterFutures: Futures(
			where: {
				AND: [
					{ displayExpiresAt: { gte: $futuresStartDate } }
					{ displayExpiresAt: { lte: $futuresEndDate } }
					{ isStandardContractSize: { equals: true } }
				]
				Product: { slug: { equals: "us-dairy-butter" } }
			}
		) {
			id
			displayExpiresAt
			barchartSymbol
			SymbolGroup {
				displayFactor
			}
		}
		NonfatDryMilkFutures: Futures(
			where: {
				AND: [
					{ displayExpiresAt: { gte: $futuresStartDate } }
					{ displayExpiresAt: { lte: $futuresEndDate } }
					{ isStandardContractSize: { equals: true } }
				]
				Product: { slug: { equals: "us-dairy-nonfat-milk" } }
			}
		) {
			id
			displayExpiresAt
			barchartSymbol
			SymbolGroup {
				displayFactor
			}
		}
		CheeseFutures: Futures(
			where: {
				AND: [
					{ displayExpiresAt: { gte: $futuresStartDate } }
					{ displayExpiresAt: { lte: $futuresEndDate } }
					{ isStandardContractSize: { equals: true } }
				]
				Product: { slug: { equals: "us-dairy-cheese" } }
			}
		) {
			id
			displayExpiresAt
			barchartSymbol
			SymbolGroup {
				displayFactor
			}
		}
		DryWheyFutures: Futures(
			where: {
				AND: [
					{ displayExpiresAt: { gte: $futuresStartDate } }
					{ displayExpiresAt: { lte: $futuresEndDate } }
					{ isStandardContractSize: { equals: true } }
				]
				Product: { slug: { equals: "us-dairy-dry-whey" } }
			}
		) {
			id
			displayExpiresAt
			barchartSymbol
			SymbolGroup {
				displayFactor
			}
		}
		CornFutures: Futures(
			where: {
				AND: [
					{ displayExpiresAt: { gte: $futuresStartDate } }
					{ displayExpiresAt: { lte: $futuresEndDate } }
					{ isStandardContractSize: { equals: true } }
				]
				Product: { slug: { equals: "grain-corn" } }
			}
			orderBy: { displayExpiresAt: Asc }
		) {
			id
			displayExpiresAt
			barchartSymbol
			SymbolGroup {
				displayFactor
				barchartRootSymbol
			}
		}
		SoybeanMealFutures: Futures(
			where: {
				AND: [
					{ displayExpiresAt: { gte: $futuresStartDate } }
					{ displayExpiresAt: { lte: $futuresEndDate } }
					{ isStandardContractSize: { equals: true } }
				]
				Product: { slug: { equals: "grain-soybean-meal" } }
			}
			orderBy: { displayExpiresAt: Asc }
		) {
			id
			displayExpiresAt
			barchartSymbol
			SymbolGroup {
				displayFactor
				barchartRootSymbol
			}
		}
		AggregateExpenseLedgerEntries: AggregateLedgerEntries(
			calc: { sum: { calculatedAmount: true } }
			groupBy: { month: true, year: true, LedgerCategory: { type: true } }
			scopeId: $customerId
			where: { LedgerCategory: { type: { in: [Expense, Feed] } }, date: { gte: $startDate, lte: $endDate } }
		) {
			sum {
				calculatedAmount
			}
			month
			year
			LedgerCategory {
				type
			}
		}
		AggregateRevenueLedgerEntries: AggregateLedgerEntries(
			calc: { sum: { calculatedAmount: true } }
			groupBy: { month: true, year: true }
			scopeId: $customerId
			where: { LedgerCategory: { type: { equals: Revenue } }, date: { gte: $startDate, lte: $endDate } }
		) {
			sum {
				calculatedAmount
			}
			month
			year
		}
		AggregateLedgerForecastedEntries(
			where: { LedgerCategory: { type: { equals: $typeOfLedgerCategory } }, date: { gte: $startDate, lte: $endDate } }
			scopeId: $customerId
			calc: { sum: { calculatedAmount: true } }
			groupBy: { month: true, year: true }
		) {
			sum {
				calculatedAmount
			}
			month
			year
		}
		AggregateDairyBrokerageHedgedValues: AggregateAllocatedForecastedHedgedAndCappedVolumes(
			where: $aggregateDairyBrokerageHedgedValuesWhere
			groupBy: { date: true, Product: { slug: true, id: true } }
			calc: { sum: { naturallyShortHedged: true, naturallyShortCapped: true, naturallyLongHedged: true, naturallyLongCapped: true } }
		) {
			Product {
				slug
				id
			}
			date
			sum {
				naturallyLongCapped
				naturallyLongHedged
				naturallyShortCapped
				naturallyShortHedged
			}
		}
		FeedIngredientConsumedAndPurchasedVolumes(
			where: { FeedIngredient: { FeedIngredientVersion: { isCurrent: { equals: true } } }, startDate: $startDate, endDate: $endDate }
			orderBy: { monthStartDate: Asc }
			scopeId: $customerId
		) {
			id
			feedIngredientId
			monthStartDate
			purchasedInTons
			totalPurchasedCostInUsd
			forecastedConsumptionInTons
			FeedIngredient {
				id
				versionedConceptSeriesId
				name
				cmePercentageBasis
				cmeUsdBasis
				flatPricePerTon
				FeedIngredientPrices {
					id
					date
					price
					pricingMethodology
				}
				FeedCategory {
					id
					name
					defaultFlatPricePerTon
					defaultCmePercentageBasis
					defaultCmeUsdBasis
					HedgeProduct {
						id
						slug
					}
				}
			}
		}
	}
`;

const GET_INSURANCE_ENDORSEMENT_ALLOCATION_RATIOS = gql`
	query InsuranceEndorsementAllocationRatiosQuery($startDate: String!, $endDate: String!, $customerId: String!) {
		InsuranceEndorsementAllocationRatios(
			where: { InsuranceEndorsement: { AsDrpInsuranceEndorsement: {} }, effectiveHedgeDate: { gte: $startDate, lte: $endDate } }
			scopeId: $customerId
			limit: 2000
		) {
			id
			effectiveHedgeDate
			RatioAdjustedDerivedDrpInsuranceEndorsement {
				quarterStartDate
				quarterEndDate
				classPriceWeightingFactor
				componentPriceWeightingFactor
				effectiveCoveredMilkProduction
				pnl
				indemnity
				producerPremiumAmount
			}
		}
	}
`;

export default class BusinessesBusinessProjectedPLRoute extends Route {
	@service declare marketData: MarketDataService;

	@tracked pigsDashboardVariables: GetPigsDashboardQueryArgs = {};
	@tracked dairyDashboardVariables: GetDairyDashboardQueryArgs = {
		startDate: '',
		endDate: '',
		typeOfLedgerCategory: TypeOfLedgerCategory.MilkCheck,
		customerId: '',
	};
	@tracked insuranceAllocationRatiosVariables: GetInsuranceEndorsementAllocationRatiosQueryArgs = {
		customerId: '',
		startDate: '',
		endDate: '',
	};

	queryParams: { [key: string]: RouteQueryParam } = {
		startDate: {
			refreshModel: true,
		},
		endDate: {
			refreshModel: true,
		},
		type: {
			refreshModel: true,
		},
	};

	getPigsDashboard = useQuery<GetPigsDashboardQuery, GetPigsDashboardQueryArgs>(this, () => [
		GET_PIGS_DASHBOARD,
		{
			variables: this.pigsDashboardVariables,
			fetchPolicy: 'network-only', // Rely on network for dashboard view as there are many mutations that will affect this data (weaned pigs, contracts, feed usage, etc)
		},
	]);

	getDairyDashboard = useQuery<GetDairyDashboardQuery, GetDairyDashboardQueryArgs>(this, () => [
		GET_DAIRY_DASHBOARD,
		{
			variables: this.dairyDashboardVariables,
			fetchPolicy: 'network-only', // Rely on network for dashboard view as there are many mutations that will affect this data (weaned pigs, contracts, feed usage, etc)
		},
	]);

	getInsuranceEndorsementAllocationRatios = useQuery<
		GetInsuranceEndorsementAllocationRatiosQuery,
		GetInsuranceEndorsementAllocationRatiosQueryArgs
	>(this, () => [
		GET_INSURANCE_ENDORSEMENT_ALLOCATION_RATIOS,
		{
			variables: this.insuranceAllocationRatiosVariables,
			fetchPolicy: 'no-cache',
		},
	]);

	async model(params: ModelParams) {
		const dashboardType = params.type;
		const startDate = params.startDate;
		const endDate = params.endDate;
		const businessModel = this.modelFor(`businesses.business`) as { Customer: CustomerEntity; businessId: string };
		const businessId = businessModel.businessId;

		if (dashboardType === 'pig-dashboard') {
			const averageFinishAgeInWeeks = businessModel.Customer?.averageFinishAgeInWeeks;
			this.pigsDashboardVariables = {
				customerId: businessId,
				aggregatePositionsCalc: {
					sum: {
						grossPnl: true,
						contractQuantity: true,
					},
				},
				aggregatePositionsGroupBy: {
					effectiveHedgeDate: true,
					instrumentId: true,
					instrumentType: true,
					optionType: true,
					Product: {
						slug: true,
					},
				},
				aggregatePositionsWhere: {
					entityId: {
						equals: businessId,
					},
					Product: {
						slug: {
							in: ['livestock-lean-hogs', 'grain-corn', 'grain-soybean-meal'],
						},
					},
					effectiveHedgeDate: {
						gte: params.startDate,
						lte: params.endDate,
					},
				},
				swineSalesPurchasesAndProducedWhere: this.generateSwineSalesPurchasesAndProducedWhere(
					businessId,
					startDate,
					endDate,
					averageFinishAgeInWeeks,
				),
				startDate: startDate,
				endDate: endDate,
			};
			await this.getPigsDashboard.promise;

			return {
				businessId: businessId,
				lastUpdatedAt: DateTime.now().toISO(),
				getPigsDashboard: this.getPigsDashboard,
				getDairyDashboard: null,
			};
		} else if (dashboardType === 'dairy-dashboard') {
			const quarterStartDate = DateTime.fromISO(startDate).startOf('quarter');
			const quarterEndDate = DateTime.fromISO(endDate).endOf('quarter');
			const futuresStartDate = quarterStartDate.minus({ month: 1 }); // Include advanced futures for milk price calculations

			const endorsementWhere: DerivedDrpEndorsementFilterDTO = {
				InsurancePolicy: {
					customerId: { equals: businessId },
				},
				AND: [
					{
						quarterEndDate: {
							gte: quarterStartDate.toISODate(),
						},
					},
					{
						quarterEndDate: {
							lte: quarterEndDate.toISODate(),
						},
					},
				],
			};

			const classiiiExposureWhere: EntityAllocatedExposureRatioFilterDTO = {
				startDate: quarterStartDate.toISODate(),
				endDate: quarterEndDate.toISODate(),
				entityId: { equals: businessId },
				Product: {
					slug: { equals: 'us-dairy-class-iii' },
				},
			};

			const classivExposureWhere: EntityAllocatedExposureRatioFilterDTO = {
				startDate: quarterStartDate.toISODate(),
				endDate: quarterEndDate.toISODate(),
				entityId: { equals: businessId },
				Product: {
					slug: { equals: 'us-dairy-class-iv' },
				},
			};

			const cheeseExposureWhere: EntityAllocatedExposureRatioFilterDTO = {
				startDate: quarterStartDate.toISODate(),
				endDate: quarterEndDate.toISODate(),
				entityId: { equals: businessId },
				Product: {
					slug: { equals: 'us-dairy-cheese' },
				},
			};

			const butterExposureWhere: EntityAllocatedExposureRatioFilterDTO = {
				startDate: quarterStartDate.toISODate(),
				endDate: quarterEndDate.toISODate(),
				entityId: { equals: businessId },
				Product: {
					slug: { equals: 'us-dairy-butter' },
				},
			};

			const dryWheyExposureWhere: EntityAllocatedExposureRatioFilterDTO = {
				startDate: quarterStartDate.toISODate(),
				endDate: quarterEndDate.toISODate(),
				entityId: { equals: businessId },
				Product: {
					slug: { equals: 'us-dairy-dry-whey' },
				},
			};

			const nonfatExposureWhere: EntityAllocatedExposureRatioFilterDTO = {
				startDate: quarterStartDate.toISODate(),
				endDate: quarterEndDate.toISODate(),
				entityId: { equals: businessId },
				Product: {
					slug: { equals: 'us-dairy-nonfat-milk' },
				},
			};

			const positionWhere: CurrentAllocationPositionFilterDTO = {
				effectiveHedgeDate: {
					gte: quarterStartDate.toISODate(),
					lte: quarterEndDate.toISODate(),
				},
			};

			const dairyPositionWhere: CurrentAllocationPositionFilterDTO = Object.assign({}, positionWhere);
			dairyPositionWhere.Product = {
				slug: {
					in: [
						'us-dairy-nonfat-milk',
						'us-dairy-dry-whey',
						'us-dairy-butter',
						'us-dairy-cheese',
						'us-dairy-class-iii',
						'us-dairy-class-iv',
					],
				},
			};

			const feedPositionWhere: CurrentAllocationPositionFilterDTO = Object.assign({}, positionWhere);
			feedPositionWhere.Product = {
				slug: {
					in: ['grain-corn', 'grain-soybean-meal'],
				},
			};

			const milkUtilizationsWhere: ForecastedMilkUtilizationFilterDTO = {
				Location: {
					customerId: {
						equals: businessId,
					},
				},
				AND: [
					{
						date: {
							gte: quarterStartDate.toISODate(),
						},
					},
					{
						date: {
							lte: quarterEndDate.toISODate(),
						},
					},
				],
			};

			const aggregateDairyBrokerageHedgedValuesWhere: AllocatedForecastedHedgedAndCappedVolumeFilterDTO = {
				entityId: {
					equals: businessId,
				},
				hedgeType: {
					equals: TypeOfAllocatedHedge.Brokerage,
				},
				Product: {
					slug: {
						in: ['us-dairy-class-iii', 'us-dairy-class-iv'],
					},
				},
				date: {
					gte: quarterStartDate.toISODate(),
					lte: quarterEndDate.toISODate(),
				},
			};

			this.dairyDashboardVariables = {
				startDate,
				endDate,
				typeOfLedgerCategory: TypeOfLedgerCategory.MilkCheck,
				customerId: businessId,
				endorsementWhere,
				classiiiExposureWhere,
				classivExposureWhere,
				cheeseExposureWhere,
				butterExposureWhere,
				dryWheyExposureWhere,
				nonfatExposureWhere,
				positionWhere,
				milkUtilizationsWhere,
				feedPositionWhere,
				dairyPositionWhere,
				aggregateDairyBrokerageHedgedValuesWhere,
				futuresStartDate: futuresStartDate.toISODate(),
				futuresEndDate: quarterEndDate.toISODate(),
			};

			this.insuranceAllocationRatiosVariables = {
				customerId: businessId,
				startDate,
				endDate,
			};

			await this.getDairyDashboard.promise;
			await this.getInsuranceEndorsementAllocationRatios.promise;

			return {
				businessId: businessId,
				lastUpdatedAt: DateTime.now().toISO(),
				getDairyDashboard: this.getDairyDashboard,
				getInsuranceEndorsementAllocationRatios: this.getInsuranceEndorsementAllocationRatios,
				getPigsDashboard: null,
			};
		}
		return {
			businessId: businessId,
			lastUpdatedAt: DateTime.now().toISO(),
			getDairyDashboard: null,
			getInsuranceEndorsementAllocationRatios: null,
			getPigsDashboard: null,
		};
	}

	generateSwineSalesPurchasesAndProducedWhere(
		customerId: string,
		startDate: string,
		endDate: string,
		averageFinishAgeInWeeks: number,
	): SwineLivestockPopulationChangeFilterDTO {
		return {
			businessId: { equals: customerId },
			OR: [
				{
					reasonType: {
						equals: TypeOfLivestockPopulationChangeReason.Sale,
					},
					date: {
						gte: startDate,
						lte: endDate,
					},
				},
				{
					reasonType: {
						in: [TypeOfLivestockPopulationChangeReason.Purchase, TypeOfLivestockPopulationChangeReason.Birth],
					},
					dob: {
						gte: formatISO(subWeeks(startOfWeek(parseISO(startDate), { weekStartsOn: 0 }), averageFinishAgeInWeeks), {
							representation: 'date',
						}),
						lte: formatISO(subWeeks(startOfWeek(parseISO(endDate), { weekStartsOn: 0 }), averageFinishAgeInWeeks), {
							representation: 'date',
						}),
					},
				},
			],
		};
	}

	generateHedgedAndCappedVolumesWhere(entityId: string, startDate: string, endDate: string) {
		return {
			entityId: {
				equals: entityId,
			},
			hedgeType: {
				equals: TypeOfHedge.Brokerage,
			},
			instrumentType: {
				in: [TypeOfInstrument.Future, TypeOfInstrument.Option],
			},
			OR: [
				{
					optionType: {
						equals: null,
					},
				},
				{
					optionType: {
						equals: TypeOfOption.Call,
					},
				},
			],
			date: {
				gte: startDate,
				lte: endDate,
			},
			Product: {
				slug: {
					in: ['grain-corn', 'grain-soybean-meal'],
				},
			},
		} as ForecastedHedgedAndCappedVolumeFilterDTO;
	}
}
