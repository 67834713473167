import Big from 'big.js';

function formatNumber(value: number): number {
	const bigValue = new Big(value);
	return bigValue.toNumber();
}

function subtract(a: number, b: number): number {
	const bigA = new Big(a);
	const bigB = new Big(b);
	const result = bigA.minus(bigB);
	return formatNumber(parseFloat(result.toString()));
}

export { subtract };
