/* eslint-disable ember/no-controller-access-in-routes */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import {
	Count,
	Query_TransactionsArgs,
	SortByDirection,
	Transaction,
	TransactionFilterDTO,
	TransactionSortByDTO,
	TypeOfInstrument,
	TypeOfOption,
} from 'vault-client/types/graphql-types';
import { gql, useQuery } from 'glimmer-apollo';

type SortObj = {
	valuePath: string;
	isAscending: boolean;
};

export const GET_TRANSACTIONS = gql`
	query Transactions($limit: Float = 50, $offset: Float = 0, $orderBy: TransactionSortByDTO, $where: TransactionFilterDTO) {
		Transactions(limit: $limit, offset: $offset, orderBy: $orderBy, where: $where) {
			id
			contractQuantity
			unitQuantity
			price
			tradeDate
			feeTotal
			commissionTotal
			commissionAndFeeTotal
			Account {
				id
				accountNumber
				name
				... on BrokerageAccount {
					salesCode
					FCM {
						id
						name
					}
				}
				... on SwapAccount {
					SwapDealer {
						id
						name
					}
				}
			}
			Instrument {
				id
				name
				SymbolGroup {
					fractionDigits
					displayFactor
				}
				Product {
					id
					name
					displayFactor
					fractionDigits
					optionsUnitValue
					pointValue
					StandardProductLotSpecification {
						pointValue
					}
					velaRootSymbol
				}
				... on Option {
					optionType
					strike
					expiresAt
					displayExpiresAt
					barchartSymbol
					exchangeSymbol
					UnderlyingInstrument {
						id
						barchartSymbol
					}
				}
				... on Future {
					expiresAt
					displayExpiresAt
					barchartSymbol
					exchangeSymbol
				}
				... on Swap {
					expiresAt
					displayExpiresAt
					PriceInstrument {
						id
						... on Future {
							barchartSymbol
						}
					}
				}
				... on Swaption {
					expiresAt
					displayExpiresAt
					strike
					optionType
					PriceInstrument {
						id
						... on Future {
							barchartSymbol
						}
					}
				}
			}
		}
		TransactionCount(where: $where) {
			count
		}
	}
`;

export type GetTransactionsQuery = {
	__typename?: 'Query';

	TransactionCount: Count;
	Transactions: Transaction;
};

export default class TransactionsIndexRoute extends Route {
	@service applicationScope: any;

	templateName = 'transactions/index';

	@tracked previousRoute = false;
	@tracked transitionQueryParams = {};
	@tracked variables: Query_TransactionsArgs = {};

	queryParams = {
		accountId: { refreshModel: true },
		instrumentId: { refreshModel: true },
		customerId: { refreshModel: true },
		productId: { refreshModel: true },
		salesCode: { refreshModel: true },
		instrumentType: { refreshModel: true },
		optionType: { refreshModel: true },
		tradeDateStartDate: { refreshModel: true },
		tradeDateEndDate: { refreshModel: true },
		expMonthStartDate: { refreshModel: true },
		expMonthEndDate: { refreshModel: true },
		page: { refreshModel: true },
		sorts: { refreshModel: true },
		side: { refreshModel: true },
		size: { refreshModel: true },
	};

	getTransactions = useQuery<GetTransactionsQuery, Query_TransactionsArgs>(this, () => [GET_TRANSACTIONS, { variables: this.variables }]);

	generateOrderBy(sorts: SortObj[]): TransactionSortByDTO {
		const orderBy: {
			[key: string]: any;
		} = {};

		sorts.forEach((sort: any) => {
			sort.valuePath === 'quantityInContracts' && (sort.valuePath = 'contractQuantity');
			sort.valuePath === 'commission' && (sort.valuePath = 'commissionTotal');
			sort.valuePath === 'nonCommissionFees' && (sort.valuePath = 'feeTotal');
			const parts = sort.valuePath.split('.');
			const firstPart = parts.shift();
			let value: any = sort.isAscending ? SortByDirection.Asc : SortByDirection.Desc;

			while (parts.length > 0) {
				const part = parts.pop();
				const obj: {
					[key: string]: any;
				} = {};
				obj[part] = value;
				value = obj;
			}

			if (firstPart === 'Account') {
				orderBy.Account = { ...value };
			} else if (firstPart === 'Instrument') {
				orderBy.Instrument = {
					...value,
				};
			} else {
				orderBy[firstPart] = value;
			}
		});

		return orderBy;
	}

	generateWhere(
		organizationId: null | string,
		customerId: undefined | null | string,
		accountId: string | null | undefined,
		instrumentId: string | null | undefined,
		productId: string | null | undefined,
		salesCode: string | null | undefined,
		instrumentType: string | null | undefined,
		optionType: string | null | undefined,
		tradeDateStartDate: string | null,
		tradeDateEndDate: string | null,
		expMonthStartDate: string | null,
		expMonthEndDate: string | null,
		side: string | null | undefined,
	): TransactionFilterDTO {
		const where: TransactionFilterDTO = {};

		if (customerId) {
			where.Account = {
				customerId: { equals: customerId },
			};
		} else if (organizationId) {
			where.Account = {
				OR: [
					{
						Customer: {
							organizationId: { equals: organizationId },
						},
					},
					{
						ownerId: { equals: organizationId },
					},
				],
			};
		}

		if (customerId) {
			if (!where.Account) {
				where.Account = {};
			}
			where.Account.customerId = { equals: customerId };
		}

		if (accountId) {
			where.accountId = { equals: accountId };
		}

		if (instrumentId) {
			where.instrumentId = { equals: instrumentId };
		}

		if (salesCode) {
			where.Account = {
				...where.Account,
				salesCode: { equals: salesCode },
			};
		}

		if (instrumentType) {
			if (!where.CurrentPosition) where.CurrentPosition = {};
			where.CurrentPosition.instrumentType = { equals: instrumentType as TypeOfInstrument };
		}

		if (optionType) {
			if (!where.CurrentPosition) where.CurrentPosition = {};
			where.CurrentPosition.optionType = { equals: optionType as TypeOfOption };
		}

		if (tradeDateStartDate && tradeDateEndDate) {
			where.tradeDate = { gte: tradeDateStartDate, lte: tradeDateEndDate };
		} else if (tradeDateStartDate) {
			where.tradeDate = { gte: tradeDateStartDate };
		} else if (tradeDateEndDate) {
			where.tradeDate = { lte: tradeDateEndDate };
		}

		if (expMonthStartDate && expMonthEndDate) {
			where.Instrument = { displayExpiresAt: { gte: expMonthStartDate, lte: expMonthEndDate } };
		} else if (expMonthStartDate) {
			where.Instrument = { displayExpiresAt: { gte: expMonthStartDate } };
		} else if (expMonthEndDate) {
			where.Instrument = { displayExpiresAt: { lte: expMonthEndDate } };
		}

		if (productId) {
			where.Instrument = {
				...where.Instrument,
				productId: {
					equals: productId,
				},
			};
		}

		if (side) {
			where.quantity = side === 'Long' ? { gt: 0 } : { lt: 0 };
		}

		return where;
	}
}
